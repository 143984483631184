<template>
    <div class="cs-nav-secondary-container">
        <div class="container">
            <div class="row cs-nav-content">
                <div class="col-12 col-lg-3">
                    <div class="cs-secondary-menu">
                        <span class="cs-menu-description" v-html="active1LvlMenuItem.menu.description"></span>
                        <div
                            v-for="(lvl2menuItem, index) in active1LvlMenuItem.menu.children"
                            :key="`second-level-link-${index}`"
                            v-on:mouseover="hoverSecondLvlNav(`second-lvl-${index}`, lvl2menuItem)"
                            @click="hoverSecondLvlNav(`second-lvl-${index}`, lvl2menuItem)"
                            :class="linkClasses(`second-lvl-${index}`, 'active2LvlMenuItem')"
                        >
                            <img v-if="lvl2menuItem.image" :src="lvl2menuItem.image" />
                            <span v-if="lvl2menuItem.children && Object.keys(lvl2menuItem.children).length">{{ lvl2menuItem.title }}</span>
                            <a v-else :href="lvl2menuItem.url" target="_blank">
                              {{ lvl2menuItem.title }}
                            </a>
                            <svg v-if="lvl2menuItem.children && Object.keys(lvl2menuItem.children).length" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7.5L10 12.5L15 7.5H5Z" fill="#13314E"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div v-if="Object.keys(active2LvlMenuItem.menu).length && active2LvlMenuItem.menu.children && Object.keys(active2LvlMenuItem.menu.children).length" class="col-12 col-lg-9 side-content">
                    <div class="cs-tertiary-menu">
                        <span class="description">
                            {{ active2LvlMenuItem.menu.description }}
                        </span>
                        <div class="cs-links">
                            <div
                            v-for="(lvl3menuItem, index) in active2LvlMenuItem.menu.children"
                            :key="`third-lvl-${index}`"
                            >
                            <a :href="lvl3menuItem.url" >
                                <h6>
                                {{ lvl3menuItem.title }}
                                </h6>
                                <p>
                                {{ lvl3menuItem.description }}
                                </p>
                            </a>
                            <div class="cs-fourth-menu" v-if="lvl3menuItem.children && Object.keys(lvl3menuItem.children).length">
                                <a v-for="(lvl4menuItem, subIndex) in lvl3menuItem.children" :key="`fourth-lvl-${subIndex}`" :href="lvl4menuItem.url" >
                                {{ lvl4menuItem.title }}
                                </a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
    export default {
        name: "LargeDropDown",
        props: {
            hoverSecondLvlNav: {
                type: Function
            },
            linkClasses: {
                type: Function
            },
            active1LvlMenuItem: {
                type: Object
            },
            active2LvlMenuItem: {
                type: Object
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../styles/main.scss";

    .cs-nav-secondary-container {
      padding-top: $default_margin * 2;
      padding-bottom: $default_margin * 2;
      background:white;

      @media (max-width: 1199.98px) {
        display: none;
      }
    }

    .cs-secondary-menu {
      border-bottom: 1px solid rgb(236, 239, 248);
      padding-bottom: $default_margin;

      .cs-menu-description {
        margin: $default_margin 0px;
        display: inline-block;
        font-size: 0.95rem;
        font-weight: 500;
      }

      div {
        display: flex;
        align-items: center;
        padding: 5px;
        transition: background-color 300ms ease-out;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 100%;
        cursor: pointer;

        &:hover,
        &.active {
          background-color: $navmenu_action_color;
        }

        img {
          width: 22px;
          height: auto;
          object-fit: contain;
          object-position: center;
          margin-right: $default_margin;
        }

        span {
          font-size: 0.95rem;
          margin-right: $default_margin;
          cursor: pointer;
          user-select: none;
        }

        a {
          color: #212529;
          
          &:hover {
            text-decoration: none;
          }
        }

        svg {
          margin-left: auto;
          transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
        }
      }
    }

    .cs-tertiary-menu {
      padding-left: $default_margin * 2;
      padding-top: $default_margin * 2;

      span.description {
        width: 100%;
        display: inline-block;
        padding: 0px 0px 20px 0px;
        border-bottom: 1px solid #eceff8;
        margin-bottom: $default_margin;
      }

      .cs-links {
        display: flex;
        flex-wrap: wrap;
        margin-left: ($default_margin / 2) * -1;
        margin-right: ($default_margin / 2) * -1;
        margin-top: $default_margin * -1;

        div:not(.cs-fourth-menu) {
          margin-left: $default_margin / 2;
          margin-right: $default_margin / 2;
          margin-top: $default_margin;
          width: calc(50% - 15px);
          border-radius: 4px;
          padding: 15px;
          transition: height 300ms ease-out, background-color 300ms ease-out;
          
          &:hover {
            background-color: $navmenu_action_color;
          }

          p {
            margin-bottom: $default_margin / 2;
          }
        }

        a {
          text-decoration: none;
          color: black;

          &:hover,
          &:focus,
          &:focus-within,
          &:focus-visible {
            text-decoration: none;
            outline: none;
          }
        }

        .cs-fourth-menu {
          display: flex;
          flex-direction: column;
          
          a {
            margin-left: $default_margin / 2;
            font-size: 0.85rem;
            margin-bottom: $default_margin /2;

            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }

    .cs-nav-content {
      div:nth-child(1)[class*="col-"] {
        box-shadow: rgba(29, 140, 242, 0.16) 32px 0px 40px -32px;
        border-top: 1px solid #eceff8;
      }
      .side-content{
        max-height: calc(100vh - 137px);
        overflow-y: auto;
      }
    }
</style>